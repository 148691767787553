@use "../base/" as base;
@import "../utilities/keyframes";

.gallery__wrapper {
  flex: 1 1 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  h2 {
    flex: 1 1 100%;
    color: base.$color-secondary;
    font-family: base.$font-serif;
    font-weight: 800;
  }
}
.gallery__card--box {
  display: flex;
  margin-bottom: 10px;
  transition: 0.5s;

  &:hover {
    transform: scale(1.05);
  }

  figure {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    width: 100%;
    height: 400px;
    margin: 0;
    background: base.$color-tertiary;
    box-shadow: 0px 10px 13px -7px #000000,
      -8px -4px 15px -3px rgba(0, 0, 0, 0.06);

    img {
      flex: 0 1 60%;
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
    figcaption {
      flex: 1 1 40%;
      color: base.$color-light;
      font-size: 0.8rem;
      font-family: base.$font-sans-serif;
      padding: 10px;
      width: 90%;
      max-height: 100px;
      a {
        text-decoration: none;
        color: base.$color-light;
      }
    }
  }
}

@for $index from 1 to 12 {
  /*Target all cards for the menu and add delayed animation*/
  .gallery__wrapper .gallery__card--box:nth-of-type(#{$index}) {
    animation-delay: #{$index * 0.2}s;
    animation-name: slide-in-top;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    animation-duration: 1.2s;
  }
}

@media only screen and (min-width: 1440px) {
  .gallery__card--box {
    figure {
      flex: 1 1 100%;

      figcaption {
        font-size: 14px;
      }
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}
