.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 38px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: black;
  font-weight: bold;
  letter-spacing: 0.2rem;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
.button:hover {
  background-color: #f5f5f5;
  color: #000;
}

.button__itemQuantity {
  height: 36px;
  width: 36px;
}

.button__edit {
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: linear-gradient(to bottom, #77b55a 5%, #72b352 100%);
  background-color: #77b55a;
  border: 1px solid #4b8f29;
  text-shadow: 0px 1px 0px #5b8a3c;
}

.button__edit:hover {
  background: linear-gradient(to bottom, #72b352 5%, #77b55a 100%);
  background-color: #72b352;
}

.button__edit:active,
.button__delete:active {
  position: relative;
  top: 1px;
}

.button__edit,
.button__delete,
.button_toggleReservation {
  display: inline-block;
  font-size: 0.6rem;
  font-family: Verdana;
  text-decoration: none;
  max-width: 150px;
  max-height: 50px;
}

.button__delete {
  box-shadow: 0px 10px 14px -7px #d91a1a;
  background: linear-gradient(to bottom, #c74550 5%, #eb1111 100%);
  background-color: #c74550;
  border: 1px solid #ab1919;
  text-shadow: 0px 1px 0px #2f6627;
}

.button__delete:hover {
  background: linear-gradient(to bottom, #eb1111 5%, #c74550 100%);
  background-color: #eb1111;
}

.loading__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 10px solid white; /* Light grey */
  border-top: 4px solid #383636; /* Black */
  border-bottom: 4px solid #383636; /* Black */
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    width: 24px;
    height: 24px;
  }
  50% {
    transform: rotate(180deg) scale(1.2);
    width: 10px;
    height: 10px;
    border: 5px solid white; /* Light grey */
  }
  100% {
    transform: rotate(360deg) scale(1);
    width: 24px;
    height: 24px;
  }
}
.actuality__container {
  flex: 1 1 80%;
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
}

.auth__wrapper {
  flex: 1 1 100%;
  display: flex;
  align-items: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.auth__form {
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth__confirmation,
.auth__unauthorized,
.errors__notfound,
#feedbackConnexion {
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
}

.auth__confirmation,
.auth__unauthorized,
.errors__notfound {
  margin: 50px;
}

.auth__account {
  flex: 1 1 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eee;
  background: #1d2b3a;
}

@media only screen and (min-width: 1440px) {
  .auth__wrapper {
    padding-bottom: 20px;
    flex-direction: row;
    align-items: flex-start;
  }
}
.gestion__tab a {
  font-size: 1.1rem;
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid #fff;
  transition: 1s all;
}
.gestion__tab a:hover {
  border-bottom: 2px solid #b11d28;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5882352941);
}

.gestion_user--form {
  flex: 1 1 60%;
}

.inputBox {
  position: relative;
  min-width: 225px;
  max-width: 275px;
  flex: 0 1 25%;
  padding-top: 16px;
}

.inputBox input,
.inputBox textarea,
.inputBox select {
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #1d2b3a;
  border-radius: 5px;
  outline: none;
  color: #fff;
  font-size: 1em;
  transition: 0.5s;
}

.inputBox label {
  position: absolute;
  left: 0;
  top: 16px;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: rgba(255, 255, 255, 0.25);
  text-transform: uppercase;
  transition: 0.5s;
}

.inputBox input:valid ~ label,
.inputBox input:focus ~ label,
.inputBox textarea:valid ~ label,
.inputBox textarea:focus ~ label,
.inputBox select:valid ~ label,
.inputBox select:focus ~ label {
  color: #00dfc4;
  transform: translateX(10px) translateY(-7px);
  font-size: 0.65em;
  padding: 0 10px;
  background: #1d2b3a;
  border-radius: 5px;
  border-left: 1px solid #00dfc4;
  border-right: 1px solid #00dfc4;
  letter-spacing: 0.2em;
}

.inputBox input:valid,
.inputBox input:focus,
.inputBox textarea:valid,
.inputBox textarea:focus,
.inputBox select:valid,
.inputBox select:focus {
  border: 1px solid #00dfc4;
}

.users__container {
  flex: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.users__list {
  width: 100%;
}
.users__list ul {
  margin: 0;
  margin-top: 20px;
  padding: 0;
}

.user__row--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  margin-bottom: 10px;
  background: #eee;
  color: black;
}

.user__row--header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.user__row--header i {
  font-size: 1.7rem;
}
.user__row--header h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.user__row--content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.user__row--content p {
  flex: 1 1 100%;
}

.user__row--username {
  align-self: center;
  font-size: 1.2em;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #333;
}

@media only screen and (min-width: 1440px) {
  .users__container {
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    align-items: flex-start;
  }
  .users__list {
    width: 100%;
    flex: 1 1 100%;
  }
  .users__list ul {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    gap: 30px;
  }
  .user__row--wrapper {
    flex: 0 1 40%;
  }
}
.presentation__wrapper {
  flex: 1 1 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 10px;
  gap: 10px;
  background: #cecece;
  box-shadow: 0px 10px 13px -7px #000000, -8px -4px 15px -3px rgba(0, 0, 0, 0.06);
  color: black;
  font-family: "Raleway", sans-serif;
}
.presentation__wrapper h2 {
  color: #b11d28;
  font-family: "Charis SIL", serif;
}
.presentation__wrapper a {
  color: #b11d28;
  font-family: "Charis SIL", serif;
  align-self: center;
  transition: 1s;
}
.presentation__wrapper a:hover {
  color: #1d2b3a;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5882352941);
  transform: scale(1.05);
}

.reglementation__wrapper {
  color: black;
}

.reglementation__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.gallery__wrapper {
  flex: 1 1 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.gallery__wrapper h2 {
  flex: 1 1 100%;
  color: #b11d28;
  font-family: "Charis SIL", serif;
  font-weight: 800;
}

.gallery__card--box {
  display: flex;
  margin-bottom: 10px;
  transition: 0.5s;
}
.gallery__card--box:hover {
  transform: scale(1.05);
}
.gallery__card--box figure {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  width: 100%;
  height: 400px;
  margin: 0;
  background: #1d2b3a;
  box-shadow: 0px 10px 13px -7px #000000, -8px -4px 15px -3px rgba(0, 0, 0, 0.06);
}
.gallery__card--box figure img {
  flex: 0 1 60%;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.gallery__card--box figure figcaption {
  flex: 1 1 40%;
  color: #fff;
  font-size: 0.8rem;
  font-family: "Raleway", sans-serif;
  padding: 10px;
  width: 90%;
  max-height: 100px;
}
.gallery__card--box figure figcaption a {
  text-decoration: none;
  color: #fff;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(1) {
  animation-delay: 0.2s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(2) {
  animation-delay: 0.4s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(3) {
  animation-delay: 0.6s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(4) {
  animation-delay: 0.8s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(5) {
  animation-delay: 1s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(6) {
  animation-delay: 1.2s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(7) {
  animation-delay: 1.4s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(8) {
  animation-delay: 1.6s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(9) {
  animation-delay: 1.8s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(10) {
  animation-delay: 2s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

/*Target all cards for the menu and add delayed animation*/
.gallery__wrapper .gallery__card--box:nth-of-type(11) {
  animation-delay: 2.2s;
  animation-name: slide-in-top;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-duration: 1.2s;
}

@media only screen and (min-width: 1440px) {
  .gallery__card--box figure {
    flex: 1 1 100%;
  }
  .gallery__card--box figure figcaption {
    font-size: 14px;
  }
  .gallery__card--box:hover {
    transform: scale(1.05);
  }
}
.reservations__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding-top: 20px;
  color: black;
  background: #fff;
}

.reservations__item--options {
  margin-right: 20px;
}

.reservations__button--cta {
  width: auto;
  font-size: 1rem;
}

.reservations__items--list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.reservations__item--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  background: #1d2b3a;
  color: #eee;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.reservations__item--inative {
  background: darkgrey;
  color: black;
}

.reservations__items--selected {
  border: 1px solid #b11d28;
}

.reservations__item--wrapper h2 {
  display: flex;
  justify-content: center;
  font-family: "Charis SIL", serif;
  font-weight: 600;
  font-size: 1.2rem;
}

.reservations__item--wrapper h3 {
  flex: 40;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}

.reservations__item--price {
  display: flex;
  flex: 60;
  justify-content: flex-end;
  margin-right: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: #b11d28;
}

.reservations__item--quantity {
  font-size: 1.2rem;
  padding-left: 5px;
}

.reservations__item--quantityDetails {
  flex: 100;
  font-size: 0.8rem;
  font-weight: 400;
}

.reservations__quantity--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 10px;
  gap: 5px;
}

.reservations__cart--wrapper {
  margin: 30px;
  gap: 10px;
}

.reservations__cart--row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.reservation__header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  width: 90%;
  align-items: center;
  padding: 10px;
}

.reservation__agenda--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  align-items: center;
  padding: 10px;
}

@media only screen and (min-width: 1020px) {
  .reservation__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .reservations__items--list {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    width: 100%;
    margin: 20px;
  }
  .reservations__item--wrapper {
    margin: 10px;
    flex: 0 1 40%;
  }
  .reservation__agenda--container {
    flex: 1 1 30%;
  }
  .reservations__cart--wrapper {
    flex: 1 1 30%;
  }
}
.object__list--container ul {
  padding: 0;
}

.objectview__container {
  flex: 1 1 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 40px;
}

.object__row--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  margin-bottom: 10px;
  background: #eee;
  color: black;
}

.object__row--header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.object__row--header i {
  font-size: 1.7rem;
}
.object__row--header h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

.object__row--content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.object__row--content p {
  flex: 1 1 100%;
}

.object__row--itemname {
  align-self: center;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #333;
}

@media only screen and (min-width: 1024px) {
  .objectview__container {
    flex-direction: row;
    margin-left: 100px;
    margin-right: 100px;
    gap: 40px;
  }
}
.gestion__wrapper {
  width: 100%;
  height: auto;
  background: #1d2b3a;
  box-shadow: 0px 10px 13px -7px #000000, -8px -4px 15px -3px rgba(0, 0, 0, 0.06);
}

.gestion__nav {
  flex: 0 1 13%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 6px;
  margin-bottom: 6px;
}

h2 {
  flex: 1 1 100%;
}

@media only screen and (min-width: 1440px) {
  .gestion__nav {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-right: 1px solid #1d2b3a;
  }
  .gestion__wrapper {
    border-radius: 15px;
    margin-top: 6px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.header__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 13px -7px #000000, -8px -4px 15px -3px rgba(0, 0, 0, 0.06);
}
.header__container .header__logo {
  width: 128px;
  height: 128px;
  margin: 10px;
}
.header__container h2 {
  color: #b11d28;
  font-family: "Charis SIL", serif;
  font-size: 1rem;
}
.header__container nav {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #b11d28;
  transition: width 3s ease-in-out;
}
.header__container nav ul {
  display: flex;
  flex: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}
.header__container nav li {
  font-size: 25px;
  list-style: none;
  padding: 10px;
}

.nav__element {
  color: #fff;
  transition: 1s;
}
.nav__element:hover {
  color: #cecece;
}

.navbar__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.navbar__item span {
  transition: 0.5s;
  width: 0%;
  font-size: 0;
}
.navbar__item:hover span {
  width: 100%;
  font-size: 1rem;
  color: white;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  letter-spacing: 0.05rem;
}

@media only screen and (min-width: 1024px) {
  .header__container {
    justify-content: space-between;
    flex-direction: row;
  }
  .header__container h2 {
    font-size: 1.5rem;
  }
  .header__container nav {
    width: auto;
    margin: 15px;
    margin-right: 50px;
    padding: 5px;
    border-radius: 15px;
  }
  .header__container nav li {
    font-size: 25px;
  }
  .header__container nav ul {
    justify-content: space-around;
    gap: 20px;
    margin: 10px;
  }
  .navbar__item {
    flex-direction: row;
  }
  .navbar__item:hover span {
    margin-left: 10px;
    letter-spacing: 0.05rem;
  }
}
footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background: #cecece;
  color: black;
}

html,
body,
.app {
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  color: #fff;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

@media only screen and (min-width: 768px) {
  main {
    flex-direction: row;
  }
}

/*# sourceMappingURL=style.css.map */
