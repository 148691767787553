@use "../utilities/mixins" as mixins;
@use "../base/" as base;

.button {
  @include mixins.flex-center(row);
  width: auto;
  height: 38px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: base.$color-dark;
  font-weight: bold;
  letter-spacing: 0.2rem;
  cursor: pointer;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #f5f5f5;
    color: #000;
  }
}

.button__itemQuantity {
  height: 36px;
  width: 36px;
}
.button__edit {
  box-shadow: 0px 10px 14px -7px #3e7327;
  background: linear-gradient(to bottom, #77b55a 5%, #72b352 100%);
  background-color: #77b55a;
  border: 1px solid #4b8f29;
  text-shadow: 0px 1px 0px #5b8a3c;
}
.button__edit:hover {
  background: linear-gradient(to bottom, #72b352 5%, #77b55a 100%);
  background-color: #72b352;
}
.button__edit:active,
.button__delete:active {
  position: relative;
  top: 1px;
}
.button__edit,
.button__delete,
.button_toggleReservation {
  display: inline-block;
  font-size: 0.6rem;
  font-family: Verdana;
  text-decoration: none;
  max-width: 150px;
  max-height: 50px;
}
.button__delete {
  box-shadow: 0px 10px 14px -7px #d91a1a;
  background: linear-gradient(to bottom, #c74550 5%, #eb1111 100%);
  background-color: #c74550;
  border: 1px solid #ab1919;
  text-shadow: 0px 1px 0px #2f6627;
}
.button__delete:hover {
  background: linear-gradient(to bottom, #eb1111 5%, #c74550 100%);
  background-color: #eb1111;
}
.loading__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 10px solid white; /* Light grey */
  border-top: 4px solid #383636; /* Black */
  border-bottom: 4px solid #383636; /* Black */
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
    width: 24px;
    height: 24px;
  }
  50% {
    transform: rotate(180deg) scale(1.2);
    width: 10px;
    height: 10px;
    border: 5px solid white; /* Light grey */
  }
  100% {
    transform: rotate(360deg) scale(1);
    width: 24px;
    height: 24px;
  }
}
