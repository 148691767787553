@import '../utilities/placeholders';

@mixin heading-shadow(
  $shadow-size: $heading-shadow-size,
  $colour: $color-primary
) {
  text-shadow: $shadow-size solid $colour;
}

@mixin flex-center($direction: row){ 
  display: flex;
  justify-content: center;
  align-items: center;

  @if($direction != row){
    flex-direction: $direction;
  }
}