@use "../base/" as base;
@use "../utilities/mixins" as mixins;

.presentation__wrapper {
  flex: 1 1 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 10px;
  gap: 10px;
  background: base.$color-primary;
  box-shadow: 0px 10px 13px -7px #000000,
    -8px -4px 15px -3px rgba(0, 0, 0, 0.06);
  color: base.$color-dark;
  font-family: base.$font-sans-serif;

  h2 {
    color: base.$color-secondary;
    font-family: base.$font-serif;
  }
  a {
    color: base.$color-secondary;
    font-family: base.$font-serif;
    align-self: center;
    transition: 1s;
  }
  a:hover {
    color: base.$color-tertiary;
    text-shadow: 0px 0px 1px #00000096;
    transform: scale(1.05);
  }
}
.reglementation__wrapper {
  color: base.$color-dark;
}
.reglementation__wrapper {
  @include mixins.flex-center(column);
  padding: 20px;
}
