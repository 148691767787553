@use '../base/' as base;

footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background: base.$color-primary;
    color: base.$color-dark;
  }