@use "../base/" as base;
.object__list--container {
  ul {
    padding: 0;
  }
}
.objectview__container {
  flex: 1 1 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 40px;
}
.object__row--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 5px #ccc;
  margin-bottom: 10px;
  background: base.$color-content;
  color: base.$color-dark;
}
.object__row--header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;

  i {
    font-size: 1.7rem;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.object__row--content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  p {
    flex: 1 1 100%;
  }
}
.object__row--itemname {
  align-self: center;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  color: #333;
}
@media only screen and (min-width: 1024px) {
  .objectview__container {
    flex-direction: row;
    margin-left: 100px;
    margin-right: 100px;
    gap: 40px;
  }
}
