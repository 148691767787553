@use "./base/" as base;
@use "./components/" as components;
@use "./layout/" as layout;

html,
body,
.app {
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: base.$color-light;
  color: #fff;
}
main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
}

@media only screen and (min-width: 768px) {
  main {
    flex-direction: row;
  }
}
