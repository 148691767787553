@use "../utilities/mixins" as mixins;
@use "../base/" as base;

.reservations__wrapper {
  @include mixins.flex-center(column);
  width: 100%;
  height: auto;
  padding-top: 20px;
  color: base.$color-dark;
  background: base.$color-light;
}

.reservations__item--options {
  margin-right: 20px;
}
.reservations__button--cta {
  width: auto;
  font-size: 1rem;
}
.reservations__items--list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.reservations__item--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  background: base.$color-tertiary;
  color: base.$color-content;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.reservations__item--inative {
  background: darkgrey;
  color: base.$color-dark;
}
.reservations__items--selected {
  border: 1px solid base.$color-secondary;
}
.reservations__item--wrapper h2 {
  display: flex;
  justify-content: center;
  font-family: base.$font-serif;
  font-weight: 600;
  font-size: 1.2rem;
}
.reservations__item--wrapper h3 {
  flex: 40;
  padding-left: 10px;
  padding-right: 10px;
  font-family: base.$font-sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}
.reservations__item--price {
  display: flex;
  flex: 60;
  justify-content: flex-end;
  margin-right: 10px;
  font-family: base.$font-sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  color: base.$color-secondary;
}
.reservations__item--quantity {
  font-size: 1.2rem;
  padding-left: 5px;
}
.reservations__item--quantityDetails {
  flex: 100;
  font-size: 0.8rem;
  font-weight: 400;
}
.reservations__quantity--wrapper {
  @include mixins.flex-center(row);
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  padding: 10px;
  gap: 5px;
}

.reservations__cart--wrapper {
  margin: 30px;
  gap: 10px;
}
.reservations__cart--row {
  @include mixins.flex-center(row);
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.reservation__header {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  width: 90%;
  align-items: center;
  padding: 10px;
}
.reservation__agenda--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  align-items: center;
  padding: 10px;
}
@media only screen and (min-width: 1020px) {
  .reservation__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .reservations__items--list {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    width: 100%;
    margin: 20px;
  }
  .reservations__item--wrapper {
    margin: 10px;
    flex: 0 1 40%;
  }
  .reservation__agenda--container {
    flex: 1 1 30%;
  }
  .reservations__cart--wrapper {
    flex: 1 1 30%;
  }
}
